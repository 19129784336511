export const precache = ["/dist/demo-drawing.js"
,"/dist/demo-prosemirror.js"
,"/dist/demo-quill.js"
,"/dist/index.js"
,"/dist/index2.js"
,"/styles/codemirror.css"
,"/styles/katex.min.css"
,"/styles/main.css"
,"/styles/monokai-sublime.min.css"
,"/styles/quill.snow.css"
]